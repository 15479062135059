import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import TablePagination from "./TablePagination";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineEdit,
  AiOutlineFileDone,
  AiOutlineFilePdf,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { Role } from "../../Utils/Role";
import {
  clearInputNameRandomString,
  generateRandomStringToAddInputName,
} from "../../Utils/BrowserAutocompleteOff";
import IconTooltip from "../Layout/IconsTooltip";
import { DateInput } from "../DateInput";
import { formatDataIt, getMonthYearText } from "../../Utils/DateFormat";
import { formatId } from "../../Utils/FormatId";
import { TbTruckDelivery } from "react-icons/tb";
import { MdEditCalendar, MdSaveAs } from "react-icons/md";
import { GrPowerCycle } from "react-icons/gr";

const CustomTable = (props) => {
  const [sort, setSort] = useState("desc");
  const [role, setRole] = useState();

  useEffect(() => {
    const actualRole = Role();
    setRole(actualRole);
  }, []);

  const handleSort = (e) => {
    clearInputNameRandomString();
    for (let i of document.querySelectorAll("th")) {
      i.classList.remove("activeIcon");
    }
    e.currentTarget.classList.add("activeIcon");

    /*creo una variabile per evitare problemi nell'aggiornamento dello stato */
    const singleSort = sort;
    const key = e.currentTarget.getAttribute("data-name");

    if (sort === "asc") {
      setSort("desc");
    } else {
      setSort("asc");
    }

    props.setFilterSort({ [key]: singleSort });
    generateRandomStringToAddInputName();
  };

  return (
    <>
      {/*Tabella lista pazienti*/}
      {props.patientList &&
        (props.patientList.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              perPage={props.perPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
            ></TablePagination>

            <Table striped bordered className={"rounded-bottom-2"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[id]"}
                  >
                    Id paziente
                    {props.filterSort &&
                    props.filterSort["sorts[id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  {!!role &&
                    ![
                      "data_entry",
                      "support",
                      "single_user",
                      "referente_aziendale",
                    ].includes(role) && (
                      <th className={"bkg-primary text-white"}>Customer ID</th>
                    )}

                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[first_name]"}
                  >
                    Nome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[first_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[last_name]"}
                  >
                    Cognome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[last_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Codice Fiscale</th>
                  <th className={"bkg-primary text-white"}>Telefono</th>
                  <th className={"bkg-primary text-white"}>Email</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[district.title]"}
                  >
                    Distretto
                    {props.filterSort &&
                    props.filterSort["sorts[district.title]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Attivo</th>
                  <th className={"bkg-primary text-white"}>
                    Data ultimo ordine
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Data ultima dispensazione
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.patientList &&
                  props.patientList.map((patient, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        <td className={"id_paziente"}>
                          <Link to={`/dettaglio-pazienti/${patient.id}`}>
                            {formatId(patient.id)}
                          </Link>
                        </td>
                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "single_user",
                            "referente_aziendale",
                          ].includes(role) && <td>{patient.entity_id}</td>}

                        <td>{patient.first_name}</td>
                        <td>{patient.last_name}</td>
                        <td>{patient.cf}</td>
                        <td>{patient.phone_number}</td>
                        <td>{patient.email}</td>
                        <td>
                          {patient.district
                            ? patient.district.display_name
                            : ""}
                        </td>
                        <td>{patient.active === true ? "SI" : "NO"}</td>
                        <td>
                          {patient.last_order_date
                            ? formatDataIt(new Date(patient.last_order_date))
                            : null}
                        </td>
                        <td>
                          {patient.last_dispensation_date
                            ? formatDataIt(
                                new Date(patient.last_dispensation_date),
                              )
                            : null}
                        </td>
                      </tr>
                    );
                  })}

                <tr></tr>
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white id_paziente"}>
                    Id paziente
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"first_name"}
                  >
                    Nome Paziente
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"last_name"}
                  >
                    Cognome Paziente
                  </th>
                  <th className={"bkg-primary text-white"}>Codice Fiscale</th>
                  <th className={"bkg-primary text-white"}>Telefono</th>
                  <th className={"bkg-primary text-white"}>Email</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"district"}
                  >
                    Distretto
                  </th>
                  <th className={"bkg-primary text-white"} data-name={"active"}>
                    Attivo
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Data ultimo ordine
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Data ultima dispensazione
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessun paziente</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/*Tabella dettaglio paziente -lista ordine paziente*/}
      {props.ordersDetailList &&
        (props.ordersDetailList.length > 0 ? (
          <>
            <Table striped bordered className={"rounded-bottom-2"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>Id ordine</th>
                  <th className={"bkg-primary text-white"}>Distretto</th>
                  <th className={"bkg-primary text-white"}>
                    Tipologia sensore
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Fornitura completa
                  </th>
                  <th className={"bkg-primary text-white"}>Durata</th>
                  <th className={"bkg-primary text-white"}>
                    Frequenza dispensazioni
                  </th>
                  <th className={"bkg-primary text-white"}>Data creazione</th>
                  <th className={"bkg-primary text-white"}>Stato</th>
                  <th className={"bkg-primary text-white"}>Data scadenza</th>
                </tr>
              </thead>
              <tbody>
                {props.ordersDetailList &&
                  props.ordersDetailList.map((order, rowIndex) => {
                    return (
                      <tr key={rowIndex} className={"align-middle"}>
                        <td className={"order_id"}>
                          <Link to={`/dettaglio-ordini/${order.id}`}>
                            {formatId(order.id)}
                          </Link>
                        </td>
                        <td>{order.payer.title}</td>
                        <td>
                          {order.sensor_type === 2
                            ? "FreeStyle Libre 2"
                            : order.sensor_type === 3
                              ? "FreeStyle Libre 3"
                              : "FreeStyle Libre 1"}
                        </td>
                        <td>{order.forniture_type === 1 ? "Si" : "No"}</td>
                        <td>{order.duration} Mesi</td>
                        <td>
                          {order.dispensing_frequency === 3
                            ? "Trimestrale"
                            : "Semestrale"}
                        </td>
                        <td>{formatDataIt(new Date(order.created_at))}</td>
                        <td>{order.status_name}</td>
                        <td>
                          {order.expiry && formatDataIt(new Date(order.expiry))}
                        </td>
                      </tr>
                    );
                  })}

                <tr></tr>
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>Id ordine</th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"first_name"}
                  >
                    Distretto
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"last_name"}
                  >
                    Tipologia sensore
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Fornitura completa
                  </th>
                  <th className={"bkg-primary text-white"}>Durata</th>
                  <th className={"bkg-primary text-white"}>
                    Frequenza dispensazioni
                  </th>
                  <th className={"bkg-primary text-white"}>Data creazione</th>
                  <th className={"bkg-primary text-white"}>Stato</th>
                  <th className={"bkg-primary text-white"}>Data scadenza</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessun Ordine</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/* Tabella lista ordini*/}
      {props.ordersList &&
        (props.ordersList.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
            <Table striped bordered className={"rounded-bottom-2"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>Id ordine</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[patient.id]"}
                  >
                    Id Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[patient.id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[patient.first_name]"}
                  >
                    Nome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[patient.first_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[patient.last_name]"}
                  >
                    Cognome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[patient.last_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Codice Fiscale</th>
                  <th className={"bkg-primary text-white"}>Distretto</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[created_at]"}
                  >
                    Data Ordine
                    {props.filterSort &&
                    props.filterSort["sorts[created_at]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Data ultima dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>Stato</th>
                  <th className={"bkg-primary text-white"}>Azioni</th>
                </tr>
              </thead>
              <tbody>
                {props.ordersList &&
                  props.ordersList.map((order, rowIndex) => {
                    return (
                      <tr key={rowIndex} className={"align-middle"}>
                        <td className={"order_id"}>
                          <Link to={`/dettaglio-ordini/${order.id}`}>
                            {formatId(order.id)}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/dettaglio-pazienti/${order.patient.id}`}>
                            {formatId(order.patient.id)}
                          </Link>
                        </td>
                        <td>{order.patient.first_name}</td>
                        <td>{order.patient.last_name}</td>
                        <td>{order.patient.cf}</td>
                        <td>{order.patient.district.display_name}</td>
                        <td>{formatDataIt(new Date(order.created_at))}</td>
                        <td>
                          {formatDataIt(
                            order.dispensations &&
                              order.dispensations.length > 0 &&
                              new Date(
                                order.dispensations[
                                  order.dispensations.length - 1
                                ].shipping_at,
                              ),
                          )}
                        </td>
                        <td>{order.status_name}</td>
                        <td style={{ minWidth: "100px" }} className={""}>
                          <div className={""}>
                            {!!role &&
                              !["data_entry", "support"].includes(role) &&
                              Number(order.status_id) === 0 && (
                                <>
                                  <IconTooltip
                                    title="Rifiuta"
                                    className={"d-inline mx-1 px-0"}
                                  >
                                    <AiOutlineCloseCircle
                                      className={"text-danger fs-3"}
                                      data-value={2}
                                      data-id={order.id}
                                      data-patient-id={order.patient.id}
                                      onClick={props.handleStatusChange}
                                    />
                                  </IconTooltip>
                                  <IconTooltip
                                    title="Approva"
                                    className={"d-inline mx-1 px-0"}
                                  >
                                    <AiOutlineCheckCircle
                                      className={"text-success fs-3"}
                                      data-value={1}
                                      data-id={order.id}
                                      data-patient-id={order.patient.id}
                                      onClick={props.handleStatusChange}
                                    />
                                  </IconTooltip>
                                </>
                              )}
                            {!!role &&
                              !["support"].includes(role) &&
                              order.status_id === 0 && (
                                <>
                                  <Link to={"/modifica-ordine/" + order.id}>
                                    <IconTooltip
                                      title="Modifica"
                                      className={"d-inline mx-1 px-0"}
                                    >
                                      <AiOutlineEdit
                                        className={"fs-3 text-primary-emphasis"}
                                      />
                                    </IconTooltip>
                                  </Link>
                                </>
                              )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                <tr></tr>
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white id_ordine"}>
                    Id ordine
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"first_name"}
                  >
                    Distretto
                    {props.filterSort &&
                    props.filterSort.first_name === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"last_name"}
                  >
                    Tipologia Sensore
                    {props.filterSort &&
                    props.filterSort.last_name === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Fornitura completa
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Frequenza Dispensazioni
                  </th>
                  <th className={"bkg-primary text-white"}>Data creazione</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"district"}
                  >
                    Stato
                    {props.filterSort && props.filterSort.district === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"active"}
                  >
                    Attivo
                    {props.filterSort && props.filterSort.active === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Data Scadenza</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessun Ordine</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/*Tabella dispensazioni in dettaglio ordine*/}
      {props.orderDetail &&
        props.orderDetail.dispensations &&
        props.orderDetail.trial_dispensations &&
        (props.orderDetail.dispensations.length ||
        props.orderDetail.trial_dispensations.length > 0 ? (
          <>
            <Table striped bordered className={"rounded-bottom-2 mb-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>ID Dispensazione</th>
                  {!!role &&
                    ![
                      "data_entry",
                      "support",
                      "single_user",
                      "referente_aziendale",
                    ].includes(role) && (
                      <th className={"bkg-primary text-white"}>
                        Increment ID<br></br>Entity ID
                      </th>
                    )}
                  {!!role &&
                    ![
                      "data_entry",
                      "support",
                      "single_user",
                      "referente_aziendale",
                    ].includes(role) && (
                      <th className={"bkg-primary text-white"}>Customer ID</th>
                    )}
                  <th className={"bkg-primary text-white"}>Id Paziente</th>
                  <th className={"bkg-primary text-white"}>
                    Data Dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>N. Prog</th>
                  <th className={"bkg-primary text-white"}>Numero sensori</th>
                  <th className={"bkg-primary text-white"}>
                    Num. Strisce (x25)
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Num. Lancette (x50)
                  </th>
                  <th className={"bkg-primary text-white"}>Lettore</th>
                  <th className={"bkg-primary text-white"}>
                    Stato dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>Tracking</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"district"}
                  >
                    Documenti di consegna
                  </th>

                  <th className={"bkg-primary text-white"}>Azioni</th>
                </tr>
              </thead>
              <tbody>
                {props.orderDetail.trial_dispensations.length > 0
                  ? props.orderDetail.trial_dispensations.map(
                      (dispensation, rowIndex) => (
                        <tr
                          key={rowIndex}
                          className={`align-middle ${dispensation.hidden ? "hidden" : ""}`}
                        >
                          <td className={"order_id"}></td>
                          {!!role &&
                            ![
                              "data_entry",
                              "support",
                              "single_user",
                              "referente_aziendale",
                            ].includes(role) && (
                              <td>
                                {dispensation.increment_id}
                                <br></br>
                                <span className={"small"}>
                                  {dispensation.entity_id}
                                </span>
                              </td>
                            )}
                          {!!role &&
                            ![
                              "data_entry",
                              "support",
                              "single_user",
                              "referente_aziendale",
                            ].includes(role) && (
                              <td>{props.orderDetail.patient.entity_id}</td>
                            )}
                          <td>
                            <Link
                              to={`/dettaglio-pazienti/${props.orderDetail.patient.id}`}
                            >
                              {props.orderDetail.patient.id}
                            </Link>
                          </td>
                          <td>
                            <DateInput
                              selectedDate={
                                props.orderDetail
                                  ? dispensation.shipped_to_magento_at
                                    ? new Date(
                                        dispensation.shipped_to_magento_at,
                                      )
                                    : new Date(dispensation.shipping_at)
                                  : new Date()
                              }
                              name={"shipping_at"}
                              handleDispensationAction={
                                props.handleDispensationAction
                              }
                              dataId={dispensation.id ?? rowIndex + 1}
                              disabled={true}
                            />
                          </td>
                          <td className={"order_id"}>{dispensation.n_prog}</td>
                          <td>{dispensation.sensors_quantity}</td>
                          <td>{dispensation.strips_quantity}</td>
                          <td>{dispensation.lancets_quantity}</td>
                          <td>
                            {dispensation.has_reader &&
                            dispensation.has_reader === true
                              ? "Si"
                              : "No"}
                          </td>
                          <td>
                            {dispensation.status_name}
                            <br></br>
                            {dispensation.suspension_reason &&
                              "(" + dispensation.suspension_reason.reason + ")"}
                          </td>
                          <td>{dispensation.last_tracking}</td>
                          <td>
                            {dispensation.has_merged ? (
                              <AiOutlineFileDone
                                onClick={props.handleDownloadPod}
                                data-id={dispensation.id}
                                data-type="merged"
                                size={20}
                                title={"POD - Delivery Note"}
                              />
                            ) : (
                              ""
                            )}
                            {!dispensation.has_merged &&
                            dispensation.has_pod ? (
                              <AiOutlineFilePdf
                                onClick={props.handleDownloadPod}
                                data-id={dispensation.id}
                                data-type="pod"
                                size={20}
                                title={"POD"}
                              />
                            ) : (
                              ""
                            )}
                            {!dispensation.has_merged &&
                            dispensation.has_delivery_note ? (
                              <TbTruckDelivery
                                onClick={props.handleDownloadPod}
                                data-id={dispensation.id}
                                data-type="delivery_note"
                                size={20}
                                title={"Delivery Note"}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td></td>
                        </tr>
                      ),
                    )
                  : props.orderDetail.dispensations.map(
                      (dispensation, rowIndex) => (
                        <tr
                          key={rowIndex}
                          className={`align-middle ${dispensation.hidden ? "hidden" : ""}`}
                        >
                          <td className={"order_id"}>
                            {formatId(dispensation.id)}
                          </td>
                          {!!role &&
                            ![
                              "data_entry",
                              "support",
                              "single_user",
                              "referente_aziendale",
                            ].includes(role) && (
                              <td>
                                {dispensation.increment_id}
                                <br></br>
                                <span className={"small"}>
                                  {dispensation.entity_id}
                                </span>
                              </td>
                            )}
                          {!!role &&
                            ![
                              "data_entry",
                              "support",
                              "single_user",
                              "referente_aziendale",
                            ].includes(role) && (
                              <td>{props.orderDetail.patient.entity_id}</td>
                            )}
                          <td>
                            <Link
                              to={`/dettaglio-pazienti/${props.orderDetail.patient.id}`}
                            >
                              {props.orderDetail.patient.id}
                            </Link>
                          </td>
                          <td>
                            <DateInput
                              className={
                                "form-control form-control-sm text-center"
                              }
                              selectedDate={
                                props.orderDetail
                                  ? dispensation.shipped_to_magento_at
                                    ? new Date(
                                        dispensation.shipped_to_magento_at,
                                      )
                                    : new Date(dispensation.shipping_at)
                                  : new Date()
                              }
                              name={"shipping_at"}
                              handleDispensationAction={
                                props.handleDispensationAction
                              }
                              dataId={dispensation.id}
                              disabled={true}
                            />
                          </td>
                          <td className={"order_id"}>{dispensation.n_prog}</td>
                          <td>{dispensation.sensors_quantity}</td>
                          <td>
                            <Form.Control
                              className={
                                "form-control form-control-sm text-center"
                              }
                              type={"number"}
                              data-id={dispensation.id}
                              data-action={"edit_dispensation_quantity"}
                              name={"strips_quantity"}
                              defaultValue={dispensation.strips_quantity}
                              disabled={true}
                            />
                          </td>
                          <td>
                            <Form.Control
                              className={
                                "form-control form-control-sm text-center"
                              }
                              type={"number"}
                              data-id={dispensation.id}
                              data-action={"edit_dispensation_quantity"}
                              name={"lancets_quantity"}
                              defaultValue={dispensation.lancets_quantity}
                              disabled={true}
                            />
                          </td>
                          <td>
                            {dispensation.has_reader &&
                            dispensation.has_reader === true
                              ? "Si"
                              : "No"}
                          </td>
                          <td>
                            {dispensation.status_name}
                            <br></br>
                            {dispensation.suspension_reason &&
                              "(" + dispensation.suspension_reason.reason + ")"}
                          </td>
                          <td>
                            <a
                              href={"#"}
                              onClick={() => {
                                props.setShowModalTracker(true);
                                props.setTrackerDispensationId(dispensation.id);
                              }}
                            >
                              {dispensation.last_tracking}
                            </a>
                          </td>
                          <td>
                            {dispensation.has_merged ? (
                              <AiOutlineFileDone
                                onClick={props.handleDownloadPod}
                                data-id={dispensation.id}
                                data-type="merged"
                                size={20}
                                title={"POD - Delivery Note"}
                              />
                            ) : (
                              ""
                            )}
                            {!dispensation.has_merged &&
                            dispensation.has_pod ? (
                              <AiOutlineFilePdf
                                onClick={props.handleDownloadPod}
                                data-id={dispensation.id}
                                data-type="pod"
                                size={20}
                                title={"POD"}
                              />
                            ) : (
                              ""
                            )}
                            {!dispensation.has_merged &&
                            dispensation.has_delivery_note ? (
                              <TbTruckDelivery
                                onClick={props.handleDownloadPod}
                                data-id={dispensation.id}
                                data-type="delivery_note"
                                size={20}
                                title={"Delivery Note"}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td style={{ minWidth: "125px" }} className={"fs-4"}>
                            <div>
                              {dispensation.can_reship && (
                                <IconTooltip
                                  title={"Riprocessa Dispensazione"}
                                  className={"px-0 mx-1"}
                                >
                                  <GrPowerCycle
                                    className={"text-danger fs-3"}
                                    data-value={2}
                                    data-id={dispensation.id}
                                    onClick={props.handleReprocessDispensation}
                                  />
                                </IconTooltip>
                              )}

                              {!!role &&
                                !["data_entry"].includes(role) &&
                                (Number(dispensation.status_id) === 0 ||
                                  Number(dispensation.status_id) === 4) && (
                                  <>
                                    {!!role &&
                                      ![
                                        "referente_aziendale",
                                        "single_user",
                                      ].includes(role) && (
                                        <>
                                          {props.showReason &&
                                            props.showReason ==
                                              dispensation.id && (
                                              <Form.Select
                                                data-action={"reason_id"}
                                                id={"reason_id"}
                                                required={"required"}
                                                name={"reason_id"}
                                                data-id={dispensation.id}
                                                onChange={
                                                  props.handleDispensationAction
                                                }
                                              >
                                                <option
                                                  disabled={"disabled"}
                                                  selected={"selected"}
                                                >
                                                  Motivazione
                                                </option>
                                                {props.reasons &&
                                                  props.reasons.map((value) => {
                                                    return (
                                                      <option value={value.id}>
                                                        {value.reason}
                                                      </option>
                                                    );
                                                  })}
                                              </Form.Select>
                                            )}

                                          {Number(dispensation.status_id) ===
                                            0 && (
                                            <IconTooltip
                                              title={
                                                props.showReason &&
                                                props.showReason ==
                                                  dispensation.id
                                                  ? "Annulla"
                                                  : "Sospendi Dispensazione"
                                              }
                                              className={"px-0 mx-1"}
                                            >
                                              <AiOutlineCloseCircle
                                                className={"text-danger fs-3"}
                                                data-value={2}
                                                data-id={dispensation.id}
                                                onClick={
                                                  props.handleSelectReason
                                                }
                                              />
                                            </IconTooltip>
                                          )}

                                          {Number(dispensation.status_id) ===
                                            4 && (
                                            <IconTooltip
                                              title="Ripristina"
                                              className={"px-0 mx-1"}
                                            >
                                              <AiOutlineCheckCircle
                                                className={"text-success fs-3"}
                                                data-value={1}
                                                data-id={dispensation.id}
                                                onClick={
                                                  props.handleDispensationAction
                                                }
                                              />
                                            </IconTooltip>
                                          )}
                                        </>
                                      )}

                                    {Number(dispensation.status_id) === 0 && (
                                      <IconTooltip
                                        title="Riprogramma Dispensazione"
                                        className={"px-0 mx-1"}
                                      >
                                        <MdEditCalendar
                                          onClick={props.handleEditData}
                                          data-id={dispensation.id}
                                          data-action={"reschedule"}
                                          className={
                                            "text-primary-emphasis fs-3"
                                          }
                                        />
                                      </IconTooltip>
                                    )}

                                    {Number(dispensation.status_id) === 0 &&
                                      (props.edit.edit &&
                                      props.edit.action ==
                                        "edit-dispensation" &&
                                      props.edit.id == dispensation.id ? (
                                        <IconTooltip
                                          title="Conferma Modifica Fornitura"
                                          className={"px-0 mx-1"}
                                        >
                                          <MdSaveAs
                                            onClick={props.handleEditData}
                                            data-id={dispensation.id}
                                            data-action={"edit-dispensation"}
                                            className={"text-success fs-3"}
                                          />
                                        </IconTooltip>
                                      ) : (
                                        <IconTooltip
                                          title="Modifica Fornitura"
                                          className={"px-0 mx-1"}
                                        >
                                          <AiOutlineEdit
                                            onClick={props.handleEditData}
                                            data-id={dispensation.id}
                                            data-action={"edit-dispensation"}
                                            className={
                                              "text-primary-emphasis fs-3"
                                            }
                                          />
                                        </IconTooltip>
                                      ))}
                                  </>
                                )}
                            </div>
                          </td>
                        </tr>
                      ),
                    )}
              </tbody>
              {props.orderDetail && !props.orderDetail.expiry && (
                <tfoot>
                  <tr>
                    <td
                      colSpan={
                        !!role &&
                        ![
                          "data_entry",
                          "support",
                          "single_user",
                          "referente_aziendale",
                        ].includes(role)
                          ? 14
                          : 12
                      }
                    >
                      Le successive dispensazioni verranno generate
                      automaticamente
                    </td>
                  </tr>
                </tfoot>
              )}
              {props.orderDetail &&
                props.orderDetail.dispensing_frequency === 1 && (
                  <tfoot>
                    <tr>
                      <td
                        colSpan={
                          !!role &&
                          ![
                            "data_entry",
                            "support",
                            "single_user",
                            "referente_aziendale",
                          ].includes(role)
                            ? 14
                            : 12
                        }
                      >
                        Invio di dispensazione singola. Sarà possibile inserire
                        un ordine analogo per lo stesso paziente a distanza di 1
                        anno
                      </td>
                    </tr>
                  </tfoot>
                )}
            </Table>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-2 mb-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>ID Dispensazione</th>
                  <th className={"bkg-primary text-white"}>Id Paziente</th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"first_name"}
                  >
                    Numero sensori
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"last_name"}
                  >
                    Num. Lancette (x50)
                  </th>
                  <th className={"bkg-primary text-white"}>Lettore</th>
                  <th className={"bkg-primary text-white"}>
                    Stato dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>Tracking</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"district"}
                  >
                    Documenti di consegna
                  </th>

                  <th className={"bkg-primary text-white"}>Azioni</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessuna dispensazione per l'ordine</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/*Dispensazioni*/}
      {props.dispensationsList &&
        (props.dispensationsList.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
            <Table striped bordered className={"rounded-bottom-2"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[order.id]"}
                  >
                    Id Ordine
                    {props.filterSort &&
                    props.filterSort["sorts[order.id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>ID Dispensazione</th>
                  {!!role &&
                    ![
                      "data_entry",
                      "support",
                      "single_user",
                      "referente_aziendale",
                    ].includes(role) && (
                      <th className={"bkg-primary text-white"}>
                        Increment ID<br></br>Entity ID
                      </th>
                    )}
                  <th onClick={handleSort} className={"bkg-primary text-white"}>
                    Data Dispensazione
                  </th>
                  {!!role &&
                    ![
                      "data_entry",
                      "support",
                      "single_user",
                      "referente_aziendale",
                    ].includes(role) && (
                      <th className={"bkg-primary text-white"}>Customer ID</th>
                    )}
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[order.patient.id]"}
                  >
                    ID Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[order.patient.id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[order.patient.first_name]"}
                  >
                    Nome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[order.patient.first_name]"] ===
                      "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[order.patient.last_name]"}
                  >
                    Cognome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[order.patient.last_name]"] ===
                      "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Codice Fiscale</th>
                  <th className={"bkg-primary text-white"}>
                    Stato Dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>Azioni</th>
                </tr>
              </thead>
              <tbody>
                {props.dispensationsList &&
                  props.dispensationsList.map((dispensation, rowIndex) => {
                    return (
                      <tr
                        key={rowIndex}
                        className={`align-middle ${dispensation.hidden ? "hidden" : ""}`}
                      >
                        <td className={"dispensation_id"}>
                          <Link
                            to={`/dettaglio-ordini/${dispensation.order_id}`}
                          >
                            {formatId(dispensation.order_id)}
                          </Link>
                        </td>
                        <td>{formatId(dispensation.id)}</td>
                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "single_user",
                            "referente_aziendale",
                          ].includes(role) && (
                            <td>
                              {dispensation.increment_id}
                              <br></br>
                              <span className={"small"}>
                                {dispensation.entity_id}
                              </span>
                            </td>
                          )}
                        <td>
                          <DateInput
                            selectedDate={
                              props.dispensationsList
                                ? dispensation.shipped_to_magento_at
                                  ? new Date(dispensation.shipped_to_magento_at)
                                  : new Date(dispensation.shipping_at)
                                : new Date()
                            }
                            name={"shipping_at"}
                            handleDispensationAction={
                              props.handleDispensationAction
                            }
                            dataId={dispensation.id}
                            disabled={true}
                          />
                        </td>
                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "single_user",
                            "referente_aziendale",
                          ].includes(role) && (
                            <td>{dispensation.order.patient.entity_id}</td>
                          )}
                        <td>
                          <Link
                            to={`/dettaglio-pazienti/${dispensation.order.patient.id}`}
                          >
                            {formatId(dispensation.order.patient.id)}
                          </Link>
                        </td>
                        <td>{dispensation.order.patient.first_name}</td>
                        <td>{dispensation.order.patient.last_name}</td>
                        <td>{dispensation.order.patient.cf}</td>
                        <td>
                          {dispensation.status_name}
                          <br></br>
                          {dispensation.suspension_reason &&
                            "(" + dispensation.suspension_reason.reason + ")"}
                        </td>
                        <td className={"fs-4"} style={{ minWidth: "100px" }}>
                          <div>
                            {!!role &&
                              !["data_entry"].includes(role) &&
                              (dispensation.status_id === 0 ||
                                dispensation.status_id === 4) && (
                                <>
                                  {!!role &&
                                    ![
                                      "referente_aziendale",
                                      "single_user",
                                    ].includes(role) && (
                                      <>
                                        {props.showReason &&
                                          props.showReason ===
                                            dispensation.id && (
                                            <Form.Select
                                              data-action={"reason_id"}
                                              id={"reason_id"}
                                              required={"required"}
                                              name={"reason_id"}
                                              data-id={dispensation.id}
                                              onChange={
                                                props.handleDispensationAction
                                              }
                                            >
                                              <option
                                                disabled={"disabled"}
                                                selected={"selected"}
                                              >
                                                Motivazione
                                              </option>
                                              {props.reasons &&
                                                props.reasons.map((value) => {
                                                  return (
                                                    <option value={value.id}>
                                                      {value.reason}
                                                    </option>
                                                  );
                                                })}
                                            </Form.Select>
                                          )}

                                        {dispensation.status_id === 0 && (
                                          <IconTooltip
                                            title={
                                              props.showReason &&
                                              props.showReason ===
                                                dispensation.id
                                                ? "Annulla"
                                                : "Sospendi"
                                            }
                                            className={"px-0 mx-1"}
                                          >
                                            <AiOutlineCloseCircle
                                              className={"text-danger fs-3"}
                                              data-value={2}
                                              data-id={dispensation.id}
                                              onClick={props.handleSelectReason}
                                            />
                                          </IconTooltip>
                                        )}

                                        {dispensation.status_id === 4 && (
                                          <IconTooltip
                                            title="Ripristina"
                                            className={"px-0 mx-1"}
                                          >
                                            <AiOutlineCheckCircle
                                              className={"text-success fs-3"}
                                              data-value={1}
                                              data-id={dispensation.id}
                                              onClick={
                                                props.handleDispensationAction
                                              }
                                            />
                                          </IconTooltip>
                                        )}
                                      </>
                                    )}

                                  {dispensation.status_id === 0 && (
                                    <IconTooltip
                                      title="Modifica"
                                      className={"px-0 mx-1"}
                                    >
                                      <AiOutlineEdit
                                        onClick={props.handleEditData}
                                        data-id={dispensation.id}
                                        className={"text-primary-emphasis fs-3"}
                                      />
                                    </IconTooltip>
                                  )}
                                </>
                              )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                <tr></tr>
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-3"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>ID Ordine</th>
                  <th className={"bkg-primary text-white"}>ID Dispensazione</th>
                  <th className={"bkg-primary text-white"}>
                    Data Dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>ID Paziente</th>
                  <th className={"bkg-primary text-white"}>Nome Paziente</th>
                  <th className={"bkg-primary text-white"}>Cognome Paziente</th>
                  <th className={"bkg-primary text-white"}>Codice Fiscale</th>
                  <th className={"bkg-primary text-white"}>
                    Stato Dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>Azioni</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessuna Dispensazione</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/* Dispensazioni Massive */}
      {props.massiveDispensationsList &&
        (props.massiveDispensationsList.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"sorts[order.id]"}
                  >
                    Id Ordine
                    {props.filterSort &&
                    props.filterSort["sorts[order.id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"sorts[id]"}
                  >
                    ID Dispensazione
                    {props.filterSort &&
                    props.filterSort["sorts[id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Data spedizione</th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"dispensation_id"}
                  >
                    Numero Sensori
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"dispensation_date"}
                  >
                    Num. Strisce (x25)
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Num. Lancette (x50)
                  </th>
                  <th className={"bkg-primary text-white"}>Lettore</th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[patient.id]"}
                  >
                    ID Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[patient.id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[patient.first_name]"}
                  >
                    Nome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[patient.first_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"sorts[patient.last_name]"}
                  >
                    Cognome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[patient.first_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Azioni
                    <Form.Check
                      onChange={props.handleSelectAll}
                      name={"select_all"}
                      className={"rounded-2 mx-2 form-check-inline"}
                    ></Form.Check>
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.massiveDispensationsList &&
                  props.massiveDispensationsList.map(
                    (dispensation, rowIndex) => {
                      return (
                        <tr key={rowIndex} className={"align-middle"}>
                          <td className={"dispensation_id"}>
                            <Link
                              to={`/dettaglio-ordini/${dispensation.order_id}`}
                            >
                              {formatId(dispensation.order_id)}
                            </Link>
                          </td>
                          <td>{formatId(dispensation.id)}</td>
                          <td>
                            {formatDataIt(new Date(dispensation.shipping_at))}
                          </td>
                          <td>{dispensation.sensors_quantity}</td>
                          <td>{dispensation.strips_quantity}</td>
                          <td>{dispensation.lancets_quantity}</td>
                          <td>{dispensation.has_reader ? "SI" : "NO"}</td>
                          <td>
                            <Link
                              to={`/dettaglio-pazienti/${dispensation.patient_id}`}
                            >
                              {formatId(dispensation.patient_id)}
                            </Link>
                          </td>
                          <td>{dispensation.patient_first_name}</td>
                          <td>{dispensation.patient_last_name}</td>
                          <td className={"fs-4"}>
                            <Form.Check
                              data-select={"allToselect"}
                              name={"allToselect"}
                              data-id={dispensation.id}
                              className={"rounded-5 mx-2 form-check-inline"}
                            ></Form.Check>
                          </td>
                        </tr>
                      );
                    },
                  )}
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>Id ordine</th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"dispensation_id"}
                  >
                    Numero Sensori
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"dispensation_date"}
                  >
                    Num. Strisce (x25)
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Num. Lancette (x50)
                  </th>
                  <th className={"bkg-primary text-white"}>Lettore</th>
                  <th className={"bkg-primary text-white"}>ID Paziente</th>
                  <th className={"bkg-primary text-white"}>Nome Paziente</th>
                  <th className={"bkg-primary text-white"}>Cognome Paziente</th>
                  <th className={"bkg-primary text-white"}>Azioni</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessuna Dispensazione</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/* Reports */}

      {/* Dispensazioni Inviate */}
      {props.sendDispensations &&
        (props.sendDispensations.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>ID Dispensazione</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[invio_ordine]"}
                  >
                    Data Invio Dispensazione
                    {props.filterSort &&
                    props.filterSort["sorts[invio_ordine]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[payer]"}
                  >
                    Payer
                    {props.filterSort &&
                    props.filterSort["sorts[payer]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"} data-name={"codpat"}>
                    Id Ordine
                  </th>
                  <th className={"bkg-primary text-white"} data-name={"codpat"}>
                    Id Paziente
                  </th>
                  <th className={"bkg-primary text-white"} data-name={"codpat"}>
                    Regione Residenza
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[nome]"}
                  >
                    Nome
                    {props.filterSort &&
                    props.filterSort["sorts[nome]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[cognome]"}
                  >
                    Cognome
                    {props.filterSort &&
                    props.filterSort["sorts[cognome]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"} data-name={"codpat"}>
                    Codice Fiscale
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"prodotto"}
                  >
                    Prodotto
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"lettore"}
                  >
                    Lettore
                  </th>
                  <th className={"bkg-primary text-white"}>Prog. Ordine</th>
                </tr>
              </thead>
              <tbody>
                {props.sendDispensations &&
                  props.sendDispensations.map((dispensation, rowIndex) => {
                    return (
                      <tr key={rowIndex} className={"align-middle"}>
                        <td>{formatId(dispensation.dispensation_id)}</td>
                        <td>
                          {formatDataIt(new Date(dispensation.invio_ordine))}
                        </td>
                        <td>
                          {dispensation.payer}
                          <br></br>
                          <span className={"small"}>
                            {dispensation.payer_institution}
                          </span>
                        </td>
                        <td>
                          <Link
                            to={`/dettaglio-ordini/${dispensation.ld_order_id}`}
                          >
                            {formatId(dispensation.ld_order_id)}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/dettaglio-pazienti/${dispensation.codpat}`}
                          >
                            {formatId(dispensation.codpat)}
                          </Link>
                        </td>
                        <td>{dispensation.regione_residenza}</td>
                        <td>{dispensation.nome}</td>
                        <td>{dispensation.cognome}</td>
                        <td>{dispensation.codice_fiscale}</td>
                        <td>{dispensation.prodotto}</td>
                        <td>{dispensation.lettore ? "SI" : "NO"}</td>
                        <td>{dispensation.prog_ordine}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>ID Dispensazione</th>
                  <th className={"bkg-primary text-white"}>
                    Data Invio Dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>Payer</th>
                  <th className={"bkg-primary text-white"}>Id Ordine</th>
                  <th className={"bkg-primary text-white"}>Id Paziente</th>
                  <th className={"bkg-primary text-white"}>Regione</th>
                  <th className={"bkg-primary text-white"}>Nome</th>
                  <th className={"bkg-primary text-white"}>Cognome</th>
                  <th className={"bkg-primary text-white"}>Codice Fiscale</th>
                  <th className={"bkg-primary text-white"}>Prodotto</th>
                  <th className={"bkg-primary text-white"}>Lettore</th>
                  <th className={"bkg-primary text-white"}>Prog. Ordine</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessun Report</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/* Dispensazioni Bloccate */}
      {props.blockedDispensations &&
        (props.blockedDispensations.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>ID Dispensazione</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[data_creazione_ordine]"}
                  >
                    Data Creazione Ordine
                    {props.filterSort &&
                    props.filterSort["sorts[data_creazione_ordine]"] ===
                      "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[payer]"}
                  >
                    Payer
                    {props.filterSort &&
                    props.filterSort["sorts[payer]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Id Ordine</th>
                  <th className={"bkg-primary text-white"}>Id Paziente</th>
                  <th className={"bkg-primary text-white"}>Codice Ordine</th>
                  <th className={"bkg-primary text-white"}>
                    Codice Dispensazione
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[nome]"}
                  >
                    Nome
                    {props.filterSort &&
                    props.filterSort["sorts[nome]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[cognome]"}
                  >
                    Cognome
                    {props.filterSort &&
                    props.filterSort["sorts[cognome]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"error_codes"}
                  >
                    Codice Errore
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"error_descriptions"}
                  >
                    Descrizione Errore
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.blockedDispensations &&
                  props.blockedDispensations.map((dispensation, rowIndex) => {
                    return (
                      <tr key={rowIndex} className={"align-middle"}>
                        <td>{formatId(dispensation.dispensation_id)}</td>
                        <td>
                          {formatDataIt(
                            new Date(dispensation.data_creazione_ordine),
                          )}
                        </td>
                        <td>
                          {dispensation.payer}
                          <br></br>
                          <span className={"small"}>
                            {dispensation.payer_institution}
                          </span>
                        </td>
                        <td>
                          <Link
                            to={`/dettaglio-ordini/${dispensation.order_id}`}
                          >
                            {formatId(dispensation.order_id)}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/dettaglio-pazienti/${dispensation.patient_id}`}
                          >
                            {formatId(dispensation.patient_id)}
                          </Link>
                        </td>
                        <td>{dispensation.codice_ordine}</td>
                        <td>{dispensation.codice_dispensazione}</td>
                        <td>{dispensation.nome}</td>
                        <td>{dispensation.cognome}</td>
                        <td>{dispensation.error_codes}</td>
                        <td>{dispensation.error_descriptions}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>ID Dispensazione</th>
                  <th className={"bkg-primary text-white"}>
                    Data Creazione Ordine
                  </th>
                  <th className={"bkg-primary text-white"}>Payer</th>
                  <th className={"bkg-primary text-white"}>Id Ordine</th>
                  <th className={"bkg-primary text-white"}>Id Paziente</th>
                  <th className={"bkg-primary text-white"}>Codice Ordine</th>
                  <th className={"bkg-primary text-white"}>
                    Codice Dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>Nome</th>
                  <th className={"bkg-primary text-white"}>Cognome</th>
                  <th className={"bkg-primary text-white"}>Codice Errore</th>
                  <th className={"bkg-primary text-white"}>
                    Descrizione Errore
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessun Report</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/* Dispensazioni Pianificate */}
      {props.plannedDispensations &&
        (props.plannedDispensations.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[order.id]"}
                  >
                    Id Ordine
                    {props.filterSort &&
                    props.filterSort["sorts[order.id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[id]"}
                  >
                    ID Dispensazione
                    {props.filterSort &&
                    props.filterSort["sorts[id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[payer]"}
                  >
                    Payer
                    {props.filterSort &&
                    props.filterSort["sorts[payer]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Data spedizione</th>
                  <th className={"bkg-primary text-white"}>
                    Descrizione Sensore
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"dispensation_id"}
                  >
                    Numero Sensori
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"dispensation_date"}
                  >
                    Num. Strisce (x25)
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Num. Lancette (x50)
                  </th>
                  <th className={"bkg-primary text-white"}>Lettore</th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[patient.id]"}
                  >
                    ID Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[patient.id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[patient.first_name]"}
                  >
                    Nome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[patient.first_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[patient.last_name]"}
                  >
                    Cognome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[patient.last_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.plannedDispensations &&
                  props.plannedDispensations.map((dispensation, rowIndex) => {
                    return (
                      <tr key={rowIndex} className={"align-middle"}>
                        <td>
                          <Link
                            to={`/dettaglio-ordini/${dispensation.order_id}`}
                          >
                            {formatId(dispensation.order_id)}
                          </Link>
                        </td>
                        <td>{formatId(dispensation.id)}</td>
                        <td>
                          {dispensation.payer}
                          <br></br>
                          <span className={"small"}>
                            {dispensation.payer_institution}
                          </span>
                        </td>
                        <td>
                          {formatDataIt(new Date(dispensation.shipping_at))}
                        </td>
                        <td>{dispensation.sensor_desc}</td>
                        <td>{dispensation.sensors_quantity}</td>
                        <td>{dispensation.strips_quantity}</td>
                        <td>{dispensation.lancets_quantity}</td>
                        <td>{dispensation.has_reader ? "SI" : "NO"}</td>
                        <td>
                          <Link
                            to={`/dettaglio-pazienti/${dispensation.patient_id}`}
                          >
                            {formatId(dispensation.patient_id)}
                          </Link>
                        </td>
                        <td>{dispensation.patient_first_name}</td>
                        <td>{dispensation.patient_last_name}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>Id ordine</th>
                  <th className={"bkg-primary text-white"}>Id Dspensazione</th>
                  <th className={"bkg-primary text-white"}>Payer</th>
                  <th className={"bkg-primary text-white"}>Data Spedizione</th>
                  <th className={"bkg-primary text-white"}>
                    Descrizione Sensore
                  </th>
                  <th className={"bkg-primary text-white"}>Numero Sensori</th>
                  <th className={"bkg-primary text-white"}>
                    Num. Strisce (x25)
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Num. Lancette (x50)
                  </th>
                  <th className={"bkg-primary text-white"}>Lettore</th>
                  <th className={"bkg-primary text-white"}>ID Paziente</th>
                  <th className={"bkg-primary text-white"}>Nome Paziente</th>
                  <th className={"bkg-primary text-white"}>Cognome Paziente</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessun Report</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/* Dispensazioni Rese */}
      {props.returnedDispensations &&
        (props.returnedDispensations.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"sorts[dispensation_id]"}
                    onClick={handleSort}
                  >
                    ID Dispensazione
                    {props.filterSort &&
                    props.filterSort["sorts[dispensation_id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[payer]"}
                  >
                    Payer
                    {props.filterSort &&
                    props.filterSort["sorts[payer]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Data spedizione</th>
                  <th className={"bkg-primary text-white"}>Id Ordine</th>
                  <th className={"bkg-primary text-white"}>Id Paziente</th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[first_name]"}
                  >
                    Nome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[first_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[last_name]"}
                  >
                    Cognome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[last_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Data Tracking</th>
                  <th className={"bkg-primary text-white"}>Stato</th>
                </tr>
              </thead>
              <tbody>
                {props.returnedDispensations &&
                  props.returnedDispensations.map((dispensation, rowIndex) => {
                    return (
                      <tr key={rowIndex} className={"align-middle"}>
                        <td>{formatId(dispensation.dispensation_id)}</td>
                        <td>
                          {dispensation.payer}
                          <br></br>
                          <span className={"small"}>
                            {dispensation.payer_institution}
                          </span>
                        </td>
                        <td>
                          {formatDataIt(
                            new Date(dispensation.shipped_to_magento_at),
                          )}
                        </td>
                        <td>
                          <Link
                            to={`/dettaglio-ordini/${dispensation.order_id}`}
                          >
                            {formatId(dispensation.order_id)}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/dettaglio-pazienti/${dispensation.patient_id}`}
                          >
                            {formatId(dispensation.patient_id)}
                          </Link>
                        </td>
                        <td>{dispensation.first_name}</td>
                        <td>{dispensation.last_name}</td>
                        <td>
                          {formatDataIt(
                            new Date(dispensation.tracking_event_date),
                          )}
                        </td>
                        <td>{dispensation.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>Id Dispensazione</th>
                  <th className={"bkg-primary text-white"}>Payer</th>
                  <th className={"bkg-primary text-white"}>Data Spedizione</th>
                  <th className={"bkg-primary text-white"}>Id Ordine</th>
                  <th className={"bkg-primary text-white"}>Id Paziente</th>
                  <th className={"bkg-primary text-white"}>Numero Sensori</th>
                  <th className={"bkg-primary text-white"}>Nome Paziente</th>
                  <th className={"bkg-primary text-white"}>Cognome Paziente</th>
                  <th className={"bkg-primary text-white"}>Data Tracking</th>
                  <th className={"bkg-primary text-white"}>Stato</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessun Report</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/* Dispensazioni Inviate */}
      {props.billingDispensations &&
        (props.billingDispensations.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>ID Dispensazione</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[invio_ordine]"}
                  >
                    Data Invio Dispensazione
                    {props.filterSort &&
                    props.filterSort["sorts[invio_ordine]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[payer]"}
                  >
                    Payer
                    {props.filterSort &&
                    props.filterSort["sorts[payer]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"order_id"}
                  >
                    Id Ordine
                  </th>
                  <th className={"bkg-primary text-white"} data-name={"codpat"}>
                    Id Paziente
                  </th>
                  <th className={"bkg-primary text-white"} data-name={"region"}>
                    Regione Residenza
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[nome]"}
                  >
                    Nome
                    {props.filterSort &&
                    props.filterSort["sorts[nome]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[cognome]"}
                  >
                    Cognome
                    {props.filterSort &&
                    props.filterSort["sorts[cognome]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"prodotto"}
                  >
                    Prodotto
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"lettore"}
                  >
                    Lettore
                  </th>
                  <th className={"bkg-primary text-white"}>Prog. Ordine</th>
                </tr>
              </thead>
              <tbody>
                {props.billingDispensations &&
                  props.billingDispensations.map((dispensation, rowIndex) => {
                    return (
                      <tr key={rowIndex} className={"align-middle"}>
                        <td>{formatId(dispensation.dispensation_id)}</td>
                        <td>
                          {formatDataIt(new Date(dispensation.invio_ordine))}
                        </td>
                        <td>
                          {dispensation.payer}
                          <br></br>
                          <span className={"small"}>
                            {dispensation.payer_institution}
                          </span>
                        </td>
                        <td>
                          <Link
                            to={`/dettaglio-ordini/${dispensation.ld_order_id}`}
                          >
                            {formatId(dispensation.ld_order_id)}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/dettaglio-pazienti/${dispensation.codpat}`}
                          >
                            {formatId(dispensation.codpat)}
                          </Link>
                        </td>
                        <td>{dispensation.regione_residenza}</td>
                        <td>{dispensation.nome}</td>
                        <td>{dispensation.cognome}</td>
                        <td>{dispensation.prodotto}</td>
                        <td>{dispensation.lettore ? "SI" : "NO"}</td>
                        <td>{dispensation.prog_ordine}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>ID Dispensazione</th>
                  <th className={"bkg-primary text-white"}>
                    Data Invio Dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>Payer</th>
                  <th className={"bkg-primary text-white"}>Id Ordine</th>
                  <th className={"bkg-primary text-white"}>Id Paziente</th>
                  <th className={"bkg-primary text-white"}>Regione</th>
                  <th className={"bkg-primary text-white"}>Nome</th>
                  <th className={"bkg-primary text-white"}>Cognome</th>
                  <th className={"bkg-primary text-white"}>Prodotto</th>
                  <th className={"bkg-primary text-white"}>Lettore</th>
                  <th className={"bkg-primary text-white"}>Prog. Ordine</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessun Report</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {props.expiringPatients &&
        (props.expiringPatients.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              perPage={props.perPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
            ></TablePagination>

            <Table striped bordered className={"rounded-bottom-2"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[id]"}
                  >
                    Id paziente
                    {props.filterSort &&
                    props.filterSort["sorts[id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  {!!role &&
                    ![
                      "data_entry",
                      "support",
                      "single_user",
                      "referente_aziendale",
                    ].includes(role) && (
                      <th className={"bkg-primary text-white"}>Customer ID</th>
                    )}
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[first_name]"}
                  >
                    Nome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[first_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[last_name]"}
                  >
                    Cognome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[last_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Codice Fiscale</th>
                  <th className={"bkg-primary text-white"}>Telefono</th>
                  <th className={"bkg-primary text-white"}>Email</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[district.title]"}
                  >
                    Distretto
                    {props.filterSort &&
                    props.filterSort["sorts[district.title]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Attivo</th>
                  <th className={"bkg-primary text-white"}>
                    Data ultimo ordine
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Data ultima dispensazione
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.expiringPatients &&
                  props.expiringPatients.map((patient, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        <td className={"id_paziente"}>
                          <Link to={`/dettaglio-pazienti/${patient.id}`}>
                            {formatId(patient.id)}
                          </Link>
                        </td>
                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "single_user",
                            "referente_aziendale",
                          ].includes(role) && <td>{patient.customer_id}</td>}
                        <td>{patient.first_name}</td>
                        <td>{patient.last_name}</td>
                        <td>{patient.cf}</td>
                        <td>{patient.phone_number}</td>
                        <td>{patient.email}</td>
                        <td>{patient.district_name}</td>
                        <td>{patient.active === 1 ? "SI" : "NO"}</td>
                        <td>
                          {patient.last_order_date
                            ? formatDataIt(new Date(patient.last_order_date))
                            : null}
                        </td>
                        <td>
                          {patient.last_dispensation_date
                            ? formatDataIt(
                                new Date(patient.last_dispensation_date),
                              )
                            : null}
                        </td>
                      </tr>
                    );
                  })}
                <tr></tr>
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white id_paziente"}>
                    Id paziente
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"first_name"}
                  >
                    Nome Paziente
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"last_name"}
                  >
                    Cognome Paziente
                  </th>
                  <th className={"bkg-primary text-white"}>Codice Fiscale</th>
                  <th className={"bkg-primary text-white"}>Telefono</th>
                  <th className={"bkg-primary text-white"}>Email</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"district"}
                  >
                    Distretto
                  </th>
                  <th className={"bkg-primary text-white"}>Attivo</th>
                  <th className={"bkg-primary text-white"}>
                    Data ultimo ordine
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Data ultima dispensazione
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessun paziente</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {props.expiredPatients &&
        (props.expiredPatients.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              perPage={props.perPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
            ></TablePagination>

            <Table striped bordered className={"rounded-bottom-2"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[id]"}
                  >
                    Id paziente
                    {props.filterSort &&
                    props.filterSort["sorts[id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  {!!role &&
                    ![
                      "data_entry",
                      "support",
                      "single_user",
                      "referente_aziendale",
                    ].includes(role) && (
                      <th className={"bkg-primary text-white"}>Customer ID</th>
                    )}
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[first_name]"}
                  >
                    Nome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[first_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"sorts[last_name]"}
                  >
                    Cognome Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[last_name]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Codice Fiscale</th>
                  <th className={"bkg-primary text-white"}>Telefono</th>
                  <th className={"bkg-primary text-white"}>Email</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[district.title]"}
                  >
                    Distretto
                    {props.filterSort &&
                    props.filterSort["sorts[district.title]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Attivo</th>
                  <th className={"bkg-primary text-white"}>
                    Data ultimo ordine
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Data ultima dispensazione
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.expiredPatients &&
                  props.expiredPatients.map((patient, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        <td className={"id_paziente"}>
                          <Link to={`/dettaglio-pazienti/${patient.id}`}>
                            {formatId(patient.id)}
                          </Link>
                        </td>
                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "single_user",
                            "referente_aziendale",
                          ].includes(role) && <td>{patient.customer_id}</td>}
                        <td>{patient.first_name}</td>
                        <td>{patient.last_name}</td>
                        <td>{patient.cf}</td>
                        <td>{patient.phone_number}</td>
                        <td>{patient.email}</td>
                        <td>{patient.district_name}</td>
                        <td>{patient.active === 1 ? "SI" : "NO"}</td>
                        <td>
                          {patient.last_order_date
                            ? formatDataIt(new Date(patient.last_order_date))
                            : null}
                        </td>
                        <td>
                          {patient.last_dispensation_date
                            ? formatDataIt(
                                new Date(patient.last_dispensation_date),
                              )
                            : null}
                        </td>
                      </tr>
                    );
                  })}
                <tr></tr>
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white id_paziente"}>
                    Id paziente
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"first_name"}
                  >
                    Nome Paziente
                  </th>
                  <th
                    onClick={handleSort}
                    className={"bkg-primary text-white"}
                    data-name={"last_name"}
                  >
                    Cognome Paziente
                  </th>
                  <th className={"bkg-primary text-white"}>Codice Fiscale</th>
                  <th className={"bkg-primary text-white"}>Telefono</th>
                  <th className={"bkg-primary text-white"}>Email</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"district"}
                  >
                    Distretto
                  </th>
                  <th className={"bkg-primary text-white"}>Attivo</th>
                  <th className={"bkg-primary text-white"}>
                    Data ultimo ordine
                  </th>
                  <th className={"bkg-primary text-white"}>
                    Data ultima dispensazione
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessun paziente</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/*Tabella Payers*/}
      {props.payersList &&
        (props.payersList.length > 0 ? (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-3"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>Regione</th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"dispensation_id"}
                  >
                    Provincia
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"dispensation_date"}
                  >
                    Payer ID
                  </th>
                  <th className={"bkg-primary text-white"}>Nome Payer</th>
                  <th className={"bkg-primary text-white"}>Indirizzo Payer</th>
                  <th className={"bkg-primary text-white"}>Numero Pazienti</th>
                </tr>
              </thead>
              <tbody>
                {props.payersList &&
                  props.payersList.map((payer, rowIndex) => {
                    return (
                      <tr key={rowIndex} className={"align-middle"}>
                        <td className={""}>{payer.region}</td>
                        <td>{payer.province}</td>
                        <td>{payer.id}</td>
                        <td>{payer.title}</td>
                        <td>{payer.address}</td>
                        <td>{payer.total_patients_count}</td>
                      </tr>
                    );
                  })}
                <tr></tr>
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>Regione</th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"dispensation_id"}
                  >
                    Provincia
                  </th>
                  <th
                    className={"bkg-primary text-white"}
                    data-name={"dispensation_date"}
                  >
                    Payer ID
                  </th>
                  <th className={"bkg-primary text-white"}>Nome Payer</th>
                  <th className={"bkg-primary text-white"}>Indirizzo Payer</th>
                  <th className={"bkg-primary text-white"}>Numero Pazienti</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>Nessuna Dispensazione</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {/* Billing */}
      {props.billingList &&
        (props.billingList.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              perPage={props.perPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
            ></TablePagination>

            <Table striped bordered className={"rounded-bottom-2"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[id]"}
                  >
                    Id Fattura
                    {props.filterSort &&
                    props.filterSort["sorts[id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Ordine NSO</th>
                  <th className={"bkg-primary text-white"}>ID SAP/BPCS</th>
                  <th className={"bkg-primary text-white"}>Nome Payer</th>
                  <th className={"bkg-primary text-white"}>Distretto</th>
                  <th className={"bkg-primary text-white"}>Tipologia</th>
                  <th className={"bkg-primary text-white"}>Sensore</th>
                  <th className={"bkg-primary text-white"}>Anno-Mese</th>
                  <th className={"bkg-primary text-white"}>Data Creazione</th>
                  <th className={"bkg-primary text-white"}>Creato Da</th>
                </tr>
              </thead>
              <tbody>
                {props.billingList &&
                  props.billingList.map((billing, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        <td>
                          <Link
                            to={`/dettaglio-fattura/${formatId(billing.id)}`}
                          >
                            {formatId(billing.id)}
                          </Link>
                        </td>
                        <td>{billing.nso_code}</td>
                        <td>{billing.id_sap}</td>
                        <td>{billing.payer}</td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={rowIndex}>
                                {billing.districts}
                              </Tooltip>
                            }
                          >
                            <span>{billing.district}</span>
                          </OverlayTrigger>
                        </td>
                        <td>
                          {billing.billing_type === 1 ? "Quantità" : "Canone"}
                        </td>
                        <td>{"FSL " + billing.sensor_type}</td>
                        <td>{getMonthYearText(billing.year, billing.month)}</td>
                        <td>{formatDataIt(new Date(billing.created_at))}</td>
                        <td>{billing.user}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>Id Fattura</th>
                  <th className={"bkg-primary text-white"}>Ordine NSO</th>
                  <th className={"bkg-primary text-white"}>ID SAP/BPCS</th>
                  <th className={"bkg-primary text-white"}>Nome Payer</th>
                  <th className={"bkg-primary text-white"}>Distretto</th>
                  <th className={"bkg-primary text-white"}>Tipologia</th>
                  <th className={"bkg-primary text-white"}>Distretto</th>
                  <th className={"bkg-primary text-white"}>Sensore</th>
                  <th className={"bkg-primary text-white"}>Anno-Mese</th>
                  <th className={"bkg-primary text-white"}>Data Creazione</th>
                  <th className={"bkg-primary text-white"}>Creato Da</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={11}>Nessuna Fattura</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {props.billingFeeList &&
        (props.billingFeeList.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              perPage={props.perPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
            ></TablePagination>

            <Table striped bordered className={"rounded-bottom-2"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[patient_id]"}
                  >
                    Id Paziente
                    {props.filterSort &&
                    props.filterSort["sorts[patient_id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Nome Paziente</th>
                  <th className={"bkg-primary text-white"}>Cognome Paziente</th>
                  <th className={"bkg-primary text-white"}>Distretto</th>
                  <th className={"bkg-primary text-white"}>Sensore</th>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[days]"}
                  >
                    Totale Giorni Attivi
                    {props.filterSort &&
                    props.filterSort["sorts[days]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Attivo Da</th>
                  {!!role &&
                    ![
                      "data_entry",
                      "support",
                      "single_user",
                      "referente_aziendale",
                    ].includes(role) &&
                    props.handleEnableDisable && (
                      <th className={"bkg-primary text-white"}>
                        <Form.Check
                          type={"checkbox"}
                          id={"only_active"}
                          name={"only_active"}
                          label={"Nascondi Eliminati"}
                          onClick={props?.handleHideTrashedElement}
                          inline
                        ></Form.Check>
                      </th>
                    )}
                </tr>
              </thead>
              <tbody>
                {props.billingFeeList &&
                  props.billingFeeList.map((billing, rowIndex) => {
                    return (
                      <tr
                        key={rowIndex}
                        className={
                          props.handleEnableDisable && !billing.is_active
                            ? "hidden"
                            : ""
                        }
                      >
                        <td>
                          <Link
                            to={`/dettaglio-pazienti/${billing.patient_id}`}
                          >
                            {formatId(billing.patient_id)}
                          </Link>
                        </td>
                        <td>{billing.first_name}</td>
                        <td>{billing.last_name}</td>
                        <td>{billing.district_name}</td>
                        <td>{"FSL " + billing.sensor_types}</td>
                        <td>{billing.days}</td>
                        <td>
                          {formatDataIt(new Date(billing.coverage_start_date))}
                        </td>
                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "single_user",
                            "referente_aziendale",
                          ].includes(role) &&
                          props.handleEnableDisable && (
                            <td>
                              {billing.is_active ? (
                                <IconTooltip
                                  title="Disattiva"
                                  className={"d-inline mx-1 px-0"}
                                >
                                  <AiOutlineCloseCircle
                                    className={"text-danger fs-3"}
                                    data-id={billing.id}
                                    onClick={() =>
                                      props.handleEnableDisable(
                                        billing.id,
                                        "remove",
                                      )
                                    }
                                  />
                                </IconTooltip>
                              ) : (
                                <IconTooltip
                                  title="Attiva"
                                  className={"d-inline mx-1 px-0"}
                                >
                                  <AiOutlineCheckCircle
                                    className={"text-success fs-3"}
                                    data-id={billing.id}
                                    onClick={() =>
                                      props.handleEnableDisable(
                                        billing.id,
                                        "enable",
                                      )
                                    }
                                  />
                                </IconTooltip>
                              )}
                            </td>
                          )}
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}>
                    {(props.saveSearchResult || props.handleExport) && (
                      <Row className={"justify-content-center ms-0 me-0"}>
                        {props.handleExport && (
                          <>
                            <Col className={"col-md-4"}>
                              <button
                                className={
                                  "btn bg-primary text-uppercase text-white w-100"
                                }
                                onClick={() => props?.handleExport("export")}
                              >
                                Export XLS
                              </button>
                            </Col>

                            <Col className={"col-md-4"}>
                              <button
                                className={
                                  "btn bg-primary text-uppercase text-white w-100"
                                }
                                onClick={() => props?.handleExport("pdf")}
                              >
                                Export PDF
                              </button>
                            </Col>

                            {props?.billingDetail?.exportable && (
                              <Col className={"col-md-4"}>
                                <button
                                  className={
                                    "btn bg-primary text-uppercase text-white w-100"
                                  }
                                  onClick={() => props?.handleExport("txt")}
                                >
                                  Export TXT
                                </button>
                              </Col>
                            )}
                          </>
                        )}

                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "single_user",
                            "referente_aziendale",
                          ].includes(role) &&
                          props.saveSearchResult && (
                            <Col className={"col-md-4"}>
                              <button
                                disabled={props.isButtonDisabled}
                                className={
                                  "btn bg-primary text-uppercase text-white w-100"
                                }
                                onClick={props?.saveSearchResult}
                              >
                                Salva
                              </button>
                            </Col>
                          )}
                      </Row>
                    )}
                  </td>
                  <td>{"TOTALE GIORNI: " + (props.totalDays ?? 0)}</td>
                  {!!role &&
                  ![
                    "data_entry",
                    "support",
                    "single_user",
                    "referente_aziendale",
                  ].includes(role) &&
                  props.handleEnableDisable ? (
                    <td colSpan={3}></td>
                  ) : (
                    <td colSpan={2}></td>
                  )}
                </tr>
              </tfoot>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>Id Paziente</th>
                  <th className={"bkg-primary text-white"}>Nome Paziente</th>
                  <th className={"bkg-primary text-white"}>Cognome Paziente</th>
                  <th className={"bkg-primary text-white"}>Distretto</th>
                  <th className={"bkg-primary text-white"}>Sensore</th>
                  <th className={"bkg-primary text-white"}>
                    Totale Giorni Attivi
                  </th>
                  <th className={"bkg-primary text-white"}>Attivo Da</th>
                  {!!role &&
                    ![
                      "data_entry",
                      "support",
                      "single_user",
                      "referente_aziendale",
                    ].includes(role) &&
                    props.handleEnableDisable && (
                      <th className={"bkg-primary text-white"}>
                        <Form.Check
                          type={"checkbox"}
                          id={"only_active"}
                          name={"only_active"}
                          label={"Nascondi Eliminati"}
                          onClick={props?.handleHideTrashedElement}
                          inline
                        ></Form.Check>
                      </th>
                    )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={8}>Nessun Elemento</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}

      {props.billingQuantityList &&
        (props.billingQuantityList.length > 0 ? (
          <>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              perPage={props.perPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
            ></TablePagination>

            <Table striped bordered className={"rounded-bottom-2"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th
                    className={"bkg-primary text-white"}
                    onClick={handleSort}
                    data-name={"sorts[dispensation_id]"}
                  >
                    Id Dispensazione
                    {props.filterSort &&
                    props.filterSort["sorts[dispensation_id]"] === "asc" ? (
                      <AiOutlineSortAscending
                        className={"fs-4"}
                      ></AiOutlineSortAscending>
                    ) : (
                      <AiOutlineSortDescending
                        className={"fs-4"}
                      ></AiOutlineSortDescending>
                    )}
                  </th>
                  <th className={"bkg-primary text-white"}>Nome Paziente</th>
                  <th className={"bkg-primary text-white"}>Cognome Paziente</th>
                  <th className={"bkg-primary text-white"}>Distretto</th>
                  <th className={"bkg-primary text-white"}>Numero Sensori</th>
                  <th className={"bkg-primary text-white"}>
                    Tipologia Sensore
                  </th>
                  <th className={"bkg-primary text-white"}>Rif. Ordini</th>
                  <th className={"bkg-primary text-white"}>
                    Stato Dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>Data Creazione</th>
                  {!!role &&
                    ![
                      "data_entry",
                      "support",
                      "single_user",
                      "referente_aziendale",
                    ].includes(role) &&
                    props.handleEnableDisable && (
                      <th className={"bkg-primary text-white"}>
                        <Form.Check
                          type={"checkbox"}
                          id={"only_active"}
                          name={"only_active"}
                          label={"Nascondi Eliminati"}
                          onClick={props?.handleHideTrashedElement}
                          inline
                        ></Form.Check>
                      </th>
                    )}
                </tr>
              </thead>
              <tbody>
                {props.billingQuantityList &&
                  props.billingQuantityList.map((billing, rowIndex) => {
                    return (
                      <tr
                        key={rowIndex}
                        className={
                          props.handleEnableDisable && !billing.is_active
                            ? "hidden"
                            : ""
                        }
                      >
                        <td>{formatId(billing.dispensation_id)}</td>
                        <td>{billing.first_name}</td>
                        <td>{billing.last_name}</td>
                        <td>{billing.district_name}</td>
                        <td>{billing.sensors}</td>
                        <td>{"FSL " + billing.sensor_type}</td>
                        <td>{billing.order_ref}</td>
                        <td>{billing.dispensation_status_description}</td>
                        <td>{formatDataIt(new Date(billing.shipping_date))}</td>
                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "single_user",
                            "referente_aziendale",
                          ].includes(role) &&
                          props.handleEnableDisable && (
                            <td>
                              {billing.is_active ? (
                                <IconTooltip
                                  title="Disattiva"
                                  className={"d-inline mx-1 px-0"}
                                >
                                  <AiOutlineCloseCircle
                                    className={"text-danger fs-3"}
                                    data-id={billing.id}
                                    onClick={() =>
                                      props.handleEnableDisable(
                                        billing.id,
                                        "remove",
                                      )
                                    }
                                  />
                                </IconTooltip>
                              ) : (
                                <IconTooltip
                                  title="Attiva"
                                  className={"d-inline mx-1 px-0"}
                                >
                                  <AiOutlineCheckCircle
                                    className={"text-success fs-3"}
                                    data-id={billing.id}
                                    onClick={() =>
                                      props.handleEnableDisable(
                                        billing.id,
                                        "enable",
                                      )
                                    }
                                  />
                                </IconTooltip>
                              )}
                            </td>
                          )}
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}>
                    {(props.saveSearchResult || props.handleExport) && (
                      <Row className={"justify-content-center ms-0 me-0"}>
                        {props.handleExport && (
                          <>
                            <Col className={"col-md-4"}>
                              <button
                                className={
                                  "btn bg-primary text-uppercase text-white w-100"
                                }
                                onClick={() => props?.handleExport("export")}
                              >
                                Export XLS
                              </button>
                            </Col>

                            <Col className={"col-md-4"}>
                              <button
                                className={
                                  "btn bg-primary text-uppercase text-white w-100"
                                }
                                onClick={() => props?.handleExport("pdf")}
                              >
                                Export PDF
                              </button>
                            </Col>

                            {props?.billingDetail?.exportable && (
                              <Col className={"col-md-4"}>
                                <button
                                  className={
                                    "btn bg-primary text-uppercase text-white w-100"
                                  }
                                  onClick={() => props?.handleExport("txt")}
                                >
                                  Export TXT
                                </button>
                              </Col>
                            )}
                          </>
                        )}

                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "single_user",
                            "referente_aziendale",
                          ].includes(role) &&
                          props.saveSearchResult && (
                            <Col className={"col-md-4"}>
                              <button
                                disabled={props.isButtonDisabled}
                                className={
                                  "btn bg-primary text-uppercase text-white w-100"
                                }
                                onClick={props?.saveSearchResult}
                              >
                                Salva
                              </button>
                            </Col>
                          )}
                      </Row>
                    )}
                  </td>
                  <td>{"TOTALE SENSORI: " + (props.totalSensor ?? 0)}</td>
                  <td></td>
                  <td>
                    {props.saveSearchResult && (
                      <button
                        className={
                          "btn bkg-primary text-uppercase text-white w-100"
                        }
                      >
                        <Form.Check
                          type={"checkbox"}
                          value={1}
                          id={"split_order_ref"}
                          name={"split_order_ref"}
                          label={"SPLIT FATTURA"}
                          inline
                        ></Form.Check>
                      </button>
                    )}
                  </td>
                  {!!role &&
                  ![
                    "data_entry",
                    "support",
                    "single_user",
                    "referente_aziendale",
                  ].includes(role) &&
                  props.handleEnableDisable ? (
                    <td colSpan={3}></td>
                  ) : (
                    <td colSpan={2}></td>
                  )}
                </tr>
              </tfoot>
            </Table>
            <TablePagination
              pagination={props.pagination}
              setPerPage={props.setPerPage}
              setPageUrl={props.setPageUrl}
              records={props.records}
              handleExportTable={props.handleExportTable}
              perPage={props.perPage}
            ></TablePagination>
          </>
        ) : (
          <>
            <Table striped bordered className={"rounded-bottom-2 mt-4"}>
              <thead className={"rounded-2"}>
                <tr className={"rounded-top-2"}>
                  <th className={"bkg-primary text-white"}>Id Dispensazione</th>
                  <th className={"bkg-primary text-white"}>Nome Paziente</th>
                  <th className={"bkg-primary text-white"}>Cognome Paziente</th>
                  <th className={"bkg-primary text-white"}>Distretto</th>
                  <th className={"bkg-primary text-white"}>Numero Sensori</th>
                  <th className={"bkg-primary text-white"}>
                    Tipologia Sensore
                  </th>
                  <th className={"bkg-primary text-white"}>Rif. Ordini</th>
                  <th className={"bkg-primary text-white"}>
                    Stato Dispensazione
                  </th>
                  <th className={"bkg-primary text-white"}>Data Creazione</th>
                  {!!role &&
                    ![
                      "data_entry",
                      "support",
                      "single_user",
                      "referente_aziendale",
                    ].includes(role) &&
                    props.handleEnableDisable && (
                      <th className={"bkg-primary text-white"}>
                        <Form.Check
                          type={"checkbox"}
                          id={"only_active"}
                          name={"only_active"}
                          label={"Nascondi Eliminati"}
                          onClick={props?.handleHideTrashedElement}
                          checked={true}
                          inline
                        ></Form.Check>
                      </th>
                    )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={10}>Nessun Elemento</td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}
    </>
  );
};

export default CustomTable;
