import {Button, Col, Form, Row} from "react-bootstrap";
import IconTooltip from "../Layout/IconsTooltip";
import {BiEraser} from "react-icons/bi";
import React, {useEffect, useState} from "react";
import {retrievePayerAndDistricts} from "../../Api/Districts/DistrictsFetch";
import {DateInput} from "../DateInput";
import {clearInputNameRandomString, generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";
import {retrieveUsers} from "../../Api/Settings/SettingsFetch";
import {Grouped} from "../../Utils/Grouped";

const SearchBilling = (props) => {
    const [districtsData, setDistrictsData] = useState([]);
    const [payersData, setPayersData] = useState([]);
    const [payerId, setPayerId] = useState('')
    const [districtId, setDistrictId] = useState('');
    const [errors, setErrors] = useState()
    const [users, setUsers] = useState()
    const [groupedPayers, setGroupedPayers] = useState();
    const [resetDate, setResetDate] = useState(false);

    //get payer
    useEffect(() => {
        retrievePayerAndDistricts(payerId, {setPayersData, setErrors}, null, '/all')
        retrieveUsers({setUsers, setErrors})
    }, []);

    //filter district by payer select
    useEffect(() => {
        if(payersData.length === 1){
            setPayerId(payersData[0].payer_id);
        }

        setDistrictsData(payersData.filter(payer => payer.payer_id === payerId))
    }, [payerId, payersData])

    //grouped payer
    useEffect(() => {
        setGroupedPayers(Grouped(payersData, 'region'))
    }, [payersData])

    //clear input field
    const clearInput = () => {
        props.setSearch({})
        for (let i of document.querySelectorAll('form input, form select')) {
            i.value = ''
        }

        setResetDate(true)
    }

    //handle search
    const handleSearch = (e) => {
        clearInputNameRandomString()
        setResetDate(false)
        props.setSearch({})
        props.setPageUrl('/api/billing/v1/invoice/list?page=1')
        e.preventDefault();

        for (let i of document.querySelectorAll('input')) {
            if (i.value !== '') {
                const name = i.getAttribute('name')
                let value = i.value;

                if(process.env.REACT_APP_ENVIRONMENT === 'staging'){
                    value = i.value > 1000000 ? i.value - 1000000 : i.value;
                }

                props.setSearch(search => ({
                        ...search, [name]: value
                    })
                )
            }
        }
        for (let i of document.querySelectorAll('form select')) {
            if (i.value !== '') {
                const name = i.getAttribute('name')
                const value = i.value === '0' ? '%22' + i.value + '%22' : i.value

                props.setSearch(search => ({
                        ...search, [name]: value
                    })
                )
            }
        }
        generateRandomStringToAddInputName()
    }

    return (
        <>
            <Row className={'searchForm_container rounded-3 fs-6 px-0 mx-0'}>
                <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
                    <Row>
                        <Col md={8}>
                            <p className={'mb-0 py-2'}>Ricerca Fattura</p>
                        </Col>
                        <Col md={4} className={'d-flex justify-content-end align-items-center position-relative'}>
                            <IconTooltip title="Pulisci Campi" id="t-1" action={clearInput}>
                                <BiEraser className={'fs-3 text-second d-block'}></BiEraser>
                            </IconTooltip>
                        </Col>
                    </Row>
                </Col>

                <Form className={'row py-3 text-start'} id={'searchForm'}>
                    <Form.Group className={'col-md-12 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-2'}>ID Fattura</Form.Label>
                            <Col className={'col-md-2'}>
                                <Form.Control type="text" name={'filters[id]'} className={'d-inline form-control-sm w-100'} />
                            </Col>

                            <Form.Label className={'col-md-2'}>Codice NSO</Form.Label>
                            <Col className={'col-md-2'}>
                                <Form.Control type="text" name={'filters[nso_code]'} className={'d-inline form-control-sm w-100'} />
                            </Col>

                            <Form.Label className={'col-md-2'}>ID SAP/BPCS</Form.Label>
                            <Col className={'col-md-2'}>
                                <Form.Control type="text" name={'filters[sap_id]'} className={'d-inline form-control-sm w-100'} />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className={'my-2'}>
                        <Row>
                            <Form.Label className={'col-md-2'}>Payer</Form.Label>
                            <Col className={'col-md-2 '}>
                                <Form.Select name={"filters[payer_id]"} className={'d-inline form-select-sm'}
                                             value={payerId} disabled={props.patientDetail && 'disabled'}
                                             data-value={props.patientDetail && props.patientDetail.district_id}
                                             required={'required'} onChange={(e) => setPayerId(e.target.value)}>
                                    <option value={''}>Seleziona</option>
                                    {groupedPayers && Object.keys(groupedPayers).map((region) => {
                                        return (
                                            <optgroup key={region} label={region}>
                                                {groupedPayers[region]?.map(payer => ( // Using optional chaining to handle undefined or null
                                                    <option key={payer.payer_id}
                                                            value={payer.payer_id}>{payer.payer}</option>
                                                ))}
                                            </optgroup>
                                        )
                                    })}
                                </Form.Select>
                            </Col>

                            <Form.Label className={'col-md-2'}>Distretto</Form.Label>
                            <Col className={'col-md-6 '}>
                                <Form.Select name="filters[district_id]" className={'d-inline form-select-sm'} required={'required'} onChange={(e) => setDistrictId(e.target.value)}>
                                    <option value={''}>Tutti</option>
                                    {districtsData && districtsData.map(payer => {
                                        return (
                                            payer.districts && payer.districts.map(district => {
                                                return (
                                                    <option style={{color: district.is_enabled === 0 ? "red" : "black", fontStyle: district.is_enabled === 0 ? "italic" : "normal"}} key={district.id} value={district.id}>{district.title}</option>
                                                )
                                            })
                                        )
                                    })}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className={'my-2'}>
                        <Row>
                            <Form.Label className={'col-md-2'}>Data</Form.Label>
                            <Col className={'col-md-5 d-flex '}>
                                <Form.Label className={'col-md-3'}>Dal</Form.Label>
                                <DateInput className={'d-inline form-control form-control-sm'} name={'filters[created_at][from]'} reset={resetDate} />
                            </Col>
                            <Col className={'col-md-5 d-flex'}>
                                <Form.Label className={'col-md-3'}>al</Form.Label>
                                <DateInput className={'d-inline form-control form-control-sm'} name={'filters[created_at][to]'} reset={resetDate} />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className={'col-md-12 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-2'}>User</Form.Label>
                            <Col className={'col-md-2 d-flex '}>
                                <Form.Select name="filters[created_by]" className={'d-inline form-select-sm'} required={'required'} onChange={(e) => setDistrictId(e.target.value)}>
                                    <option value={''}>Seleziona</option>
                                    {users && users.map(user => {
                                        return (
                                            <option key={user.id} value={user.id}>{user.full_name}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Col>

                            <Form.Label className={'col-md-2'}>Tipologia</Form.Label>
                            <Col className={'col-md-2'}>
                                <Form.Select name={'filters[billing_type]'} className={'d-inline form-select-sm'}>
                                    <option value={''}>Seleziona</option>
                                    <option value={1}>Quantità</option>
                                    <option value={2}>Canone</option>
                                </Form.Select>
                            </Col>

                            <Form.Label className={'col-md-2'}>Sensore</Form.Label>
                            <Col className={'col-md-2'}>
                                <Form.Select name={'filters[sensor_type]'} className={'d-inline form-select-sm'}>
                                    <option value={''}>Seleziona</option>
                                    <option value={2}>FSL2</option>
                                    <option value={3}>FSL3</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Row className={'text-center justify-content-center'}>
                        <Col className={'col-md-3'}>
                            <Button variant="primary" onClick={handleSearch} type="submit"
                                    className={'mt-3 rounded-5 px-5  text-uppercase'}>
                                Cerca
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </>
    )
}

export default SearchBilling